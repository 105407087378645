import { Injectable } from '@angular/core';;
import * as FullStory from '@fullstory/browser';
import { User } from '@nurtureboss/common/dist/types/users';

@Injectable({ providedIn: 'root' })
export class FullStoryService {
  constructor() {
    // no-op
  }

  setFullStoryUser(user:User) {
    let userId;
    let userEmail;
    if (user?.claims?.includes('admin')) {
      userId = 'admin';
      userEmail = 'admin@nurtureboss.io';
    } else if (user?.claims?.includes('partner')) {
      userId = 'partner';
      userEmail = 'partner@nurtureboss.io';
    } else {
      userId = user?._id;
      userEmail = user?.email;
    }
    try {
      if (userId && userEmail && user?.propertyName) {
        FullStory.identify(userId, {
          email: userEmail,
          displayName: user?.propertyName
        });
      } else {
        // Do not have enough info to identify the user.
      }
    } catch (e) {
      // no-op
    }
  }
}

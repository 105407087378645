<div class="page-title">
  <h2>Virtual Leasing Assistant</h2>
</div>
<div class="page">
  <div class="overflow-auto">
    <h6 class="title pull-left">VLA Bot Settings</h6>
    <button class="button button-dark pull-right" [disabled]="savingSettings" (click)="updateVLASettings()">
      <span *ngIf="savingSettings" class="spinner-border spinner-border-sm mr-1"></span>
      Update VLA Bot Settings
    </button>
  </div>
  <div class="col-md-12 mb-6">
    <div class="form-group">
      <label>Name</label>
      <input type="text" class="form-control short-input" [(ngModel)]="vlaSettings.botName" id="botName" aria-describedby="botName">
    </div>
  </div>
  <div class="col-md-12 mb-6">
    <div class="form-group">
      <label>Disable Email Threads On VLA Handoff</label>
      <br />
      <label class="switch">
        <input type="checkbox" [(ngModel)]="vlaSettings.disableEmailHandoff">
        <span class="slider round"></span>
      </label>
    </div>
  </div>
</div>
<div class="page" *ngIf="hasVoiceAssistant">
  <div class="margin-bottom-48">
    <div class="overflow-auto">
      <button class="button button-dark pull-right" [disabled]="savingSettings" (click)="updateCallTransferDestinations()">
        <span *ngIf="savingSettings" class="spinner-border spinner-border-sm mr-1"></span>
        Update Call Transfers
      </button>
    </div>
    <div class="col-md-12 mb-6 call-transfer-group">
      <div>
        <div class="full-width call-transfer-title">
          <h6 class="title pull-left">Emergency Maintenance Call Transfer </h6>
        </div>
        <div class="call-transfer-subtitle">The Voice VLA will transfer any callers who have an emergency maintenance request to this number</div>
        <div class="form-group">
          <label>Number <i placement="top" ngbTooltip="To change the emergency maintenance number, go to account settings." class="far fa-info-circle"></i></label>
          <div class="emergency-maintenance-number">{{emergencyMaintenanceDestination.number}}</div>
        </div>
        <div class="form-group">
          <label>Transfer Message <i placement="top" ngbTooltip="The transfer message is what the VLA will say to the caller just before transferring their call." class="far fa-info-circle"></i></label>
          <input type="text" class="form-control" [(ngModel)]="emergencyMaintenanceDestination.message" [disabled]="!emergencyMaintenanceDestination.number" id="emergencyMaintenanceDestinationMessage" aria-describedby="emergencyMaintenanceDestinationMessage">
        </div>
        <div class="no-border margin-bottom-48">
          <h6>Enable Emergency Maintenance Call Transfer</h6>
          <label class="switch">
          <input type="checkbox" [(ngModel)]="emergencyMaintenanceDestination.enabled" [disabled]="!emergencyMaintenanceDestination.number">
          <span class="slider round"></span>
          </label>
        </div>

        <div class="call-transfer-title">
          <h6 class="title pull-left">Representative Call Transfer</h6>
        </div>
        <div class="call-transfer-subtitle">The Voice VLA will transfer any callers who request to speak to a person or represenative to this number</div>
        <div class="form-group transfer-number">
          <label>Number</label>
          <input type="number" class="form-control number-input" [(ngModel)]="representativeDestination.number" id="representativeDestinationNumber" aria-describedby="representativeDestinationNumber">
        </div>
        <div class="form-group">
          <label>Transfer Message <i placement="top" ngbTooltip="The transfer message is what the VLA will say to the caller just before transferring their call." class="far fa-info-circle"></i></label>
          <input type="text" class="form-control" [(ngModel)]="representativeDestination.message" id="representativeDestinationMessage" aria-describedby="representativeDestinationMessage">
        </div>
        <div class="no-border margin-bottom-48">
          <h6>Enable Representative Call Transfer</h6>
          <label class="switch">
          <input type="checkbox" [(ngModel)]="representativeDestination.enabled">
          <span class="slider round"></span>
          </label>
        </div>

        <div class="call-transfer-title">
          <h6 class="title pull-left">Resident Representative Call Transfer</h6>
        </div>
        <div class="call-transfer-subtitle">The Voice VLA will transfer any callers with resident related issues to this number</div>
        <div class="form-group transfer-number">
          <label>Number</label>
          <input type="number" class="form-control number-input" [(ngModel)]="residentRepresentativeDestination.number" id="residentRepresentativeDestinationNumber" aria-describedby="residentRepresentativeDestinationNumber">
        </div>
        <div class="form-group">
          <label>Transfer Message <i placement="top" ngbTooltip="The transfer message is what the VLA will say to the caller just before transferring their call." class="far fa-info-circle"></i></label>
          <input type="text" class="form-control" [(ngModel)]="residentRepresentativeDestination.message" id="residentRepresentativeDestinationMessage" aria-describedby="residentRepresentativeDestinationMessage">
        </div>
        <div class="no-border">
          <h6>Enable Resident Representative Call Transfer</h6>
          <label class="switch">
          <input type="checkbox" [(ngModel)]="residentRepresentativeDestination.enabled">
          <span class="slider round"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
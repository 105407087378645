// Angular:
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

// Services:
import {
  AuthenticationService,
  ToastService,
  LoaderService,
  SettingsService,
  SettingType,
  VoiceVLAService,
  VLAService,
} from '@app/_services';

// Types
import { User } from '@nurtureboss/common/dist/types/users';
import { IVlaSettings, PropertySettings } from '@nurtureboss/common/dist/types/settings';

enum TransferCallDestinationType {
  EmergencyMaintenance = 'emergencyMaintenance',
  Representative = 'representative',
  ResidentRepresentative = 'residentRepresentative',
}

interface TransferCallDestination {
  number: number,
  message: string,
  enabled: boolean,
  type: TransferCallDestinationType,
}

@Component({
  selector: 'app-integrations',
  templateUrl: './vla.component.html',
  styleUrls: ['./vla.component.less']
})
export class VLASettingsComponent implements OnInit {
  user: Partial<User> = {};
  propertySettings: PropertySettings = {
    emergencyMaintenancePhoneNumber: '',
  }
  vlaSettings: Partial<IVlaSettings> = {
    disableEmailHandoff: false,
    botName: '',
  }
  originalBotName = '';
  voiceAssistant: any = {};
  savingSettings = false;
  hasVoiceAssistant = false;
  hasTextEmailAssistant = false;
  emergencyMaintenanceDestination: TransferCallDestination = {
    type: TransferCallDestinationType.EmergencyMaintenance,
    enabled: false,
    number: null,
    message: 'I am forwarding your call to emergency maintenance. Please stay on the line.',
  };
  representativeDestination: TransferCallDestination = {
    type: TransferCallDestinationType.Representative,
    enabled: false,
    number: null,
    message: 'I am forwarding your call to a representative. Please stay on the line.',
  }
  residentRepresentativeDestination: TransferCallDestination = {
    type: TransferCallDestinationType.ResidentRepresentative,
    enabled: false,
    number: null,
    message: 'I am forwarding your call to a resident representative. Please stay on the line.',
  };
  constructor(
    private authService: AuthenticationService,
    private voiceVLAService: VoiceVLAService,
    private toastService: ToastService,
    private loaderService: LoaderService,
    private settingsService: SettingsService,
    private vlaService: VLAService,
  ) {
    // no-op
  }
  
  ngOnInit() {
    this.loaderService.triggerLoader();
    this.loadData();
  }

  async loadData() {
    this.user = this.authService.currentUserValue.user;
    if (this.user.voiceAssistantId) {
      await this.loadVoiceAssistant(this.user.voiceAssistantId);
    }
    if (this.user.vlaAssistantId && this.user.textMessageAssistantId) {
      this.hasTextEmailAssistant = true;
    }
    this.propertySettings = (await this.settingsService.getSettingsByType(SettingType.Property).toPromise()).result;
    this.vlaSettings = (await this.settingsService.getSettingsByType(SettingType.VLA).toPromise()).result;
    if (this.vlaSettings) {
      this.originalBotName = this.vlaSettings.botName || '';
    }
    this.emergencyMaintenanceDestination.number = parseInt(this.propertySettings.emergencyMaintenancePhoneNumber);
    this.loaderService.stopLoader();
  }

  async loadVoiceAssistant(voiceAssistantId) {
    try {
      const res = await this.voiceVLAService.getVoiceAssistant(voiceAssistantId).toPromise();
      if (!res.result.id) {
        return;
      }

      this.hasVoiceAssistant = true;
      this.voiceAssistant = res.result;

      if (res.result.model?.tools?.length) {
        const transferCallTool = res.result.model.tools.find(tool => tool.type === 'transferCall');
        if (transferCallTool?.destinations?.length) {
          transferCallTool.destinations.forEach(destination => {
            // There is no name field on destinations so have to search descriptions for keywords
            // Descriptions are hardcoded and not editable by users so they are consistent
            if (destination.description?.toLowerCase() === ('emergency maintenance')) {
              this.emergencyMaintenanceDestination.message = destination.message;
              this.emergencyMaintenanceDestination.enabled = true;
            }
            if (destination.description?.toLowerCase() === ('representative')) {
              this.representativeDestination.number = parseInt(destination.number.slice(2)); // Slice to remove +1
              this.representativeDestination.message = destination.message;
              this.representativeDestination.enabled = true;
            }
            if (destination.description?.toLowerCase() === ('resident representative')) {
              this.residentRepresentativeDestination.number = parseInt(destination.number.slice(2)); // Slice to remove +1
              this.residentRepresentativeDestination.message = destination.message;
              this.residentRepresentativeDestination.enabled = true;
            }
          })
        }
      }
    } catch (err) {
      this.toastService.showError('Error retrieving voice VLA settings.');
    }
  }

  async updateCallTransferDestinations() {
    if (
      (this.emergencyMaintenanceDestination.enabled && !this.emergencyMaintenanceDestination.message)
      || (
        this.representativeDestination.enabled 
        && (!this.representativeDestination.message || !this.representativeDestination.number)
      )
      || (
        this.residentRepresentativeDestination.enabled 
        && (!this.residentRepresentativeDestination.message || !this.residentRepresentativeDestination.number)
      )
    ) {
      this.toastService.showError('Cannot update: Missing Call Transfer Fields');
      return;
    }

    let hasDuplicateNumberButNotMatchingMessage = false;
    const destinations = [
      this.emergencyMaintenanceDestination,
      this.representativeDestination,
      this.residentRepresentativeDestination,
    ];

    destinations.forEach(destination1 => {
      if (!destination1.enabled) {
        return;
      }
      destinations.forEach(destination2 => {
        if (!destination2.enabled) {
          return;
        }

        // If numbers are the same messages should match or voice VLA might behave weird
        if (destination1.number === destination2.number && destination1.message !== destination2.message) {
          hasDuplicateNumberButNotMatchingMessage = true;
        }
      })
    })

    if (hasDuplicateNumberButNotMatchingMessage) {
      this.toastService.showError('Destinations with the same number must have the same message.');
      return;
    }

    this.loaderService.triggerLoader();
    this.savingSettings = true;

    try {
      const payload = [];
      
      if (this.emergencyMaintenanceDestination.enabled) {
        payload.push({
          ...this.emergencyMaintenanceDestination,
          number: '+1' + this.emergencyMaintenanceDestination.number,
        });
      }
      
      if (this.representativeDestination.enabled) {
        payload.push({
          ...this.representativeDestination,
          number: '+1' + this.representativeDestination.number,
        });
      }
      
      if (this.residentRepresentativeDestination.enabled) {
        payload.push({
          ...this.residentRepresentativeDestination,
          number: '+1' + this.residentRepresentativeDestination.number,
        });
      }

      await this.voiceVLAService.updateCallTransferDestinations(this.voiceAssistant.id, payload).toPromise();
      this.toastService.showSuccess('Successfully Updated Call Transfers!');
    } catch (err) {
      this.toastService.showError('There was an error updating you call transfers.');
    }
    this.savingSettings = false;
    this.loaderService.stopLoader();
  }

  async updateVLASettings() {
    this.savingSettings = true;

    const botUpdateStatuses = {
      email: false,
      text: false,
      voice: false,
    }

    try {
      if (this.vlaSettings.botName !== this.originalBotName) {
        await this.settingsService.saveSettingsByType(SettingType.VLA, this.vlaSettings).toPromise();

        if (this.hasTextEmailAssistant) {
          await this.vlaService.rebuildAndUpdateEmailTextVLAPrompt(this.user.vlaAssistantId, 'email').toPromise();
          botUpdateStatuses.email = true;

          await this.vlaService.rebuildAndUpdateEmailTextVLAPrompt(this.user.textMessageAssistantId, 'text').toPromise();
          botUpdateStatuses.text = true;
        }

        if (this.hasVoiceAssistant) {
          await this.voiceVLAService.updateVoiceAssistantPrompt(this.voiceAssistant.id).toPromise();
          botUpdateStatuses.voice = true;
        }
      }
      
      this.originalBotName = this.vlaSettings.botName;
      this.toastService.showSuccess('Successfully Updated VLA Settings!');
    } catch (err) {
      try {
        await this.settingsService.saveSettingsByType(SettingType.VLA, { ...this.vlaSettings, botName: this.originalBotName }).toPromise();

        if (botUpdateStatuses.email) {
          await this.vlaService.rebuildAndUpdateEmailTextVLAPrompt(this.user.vlaAssistantId, 'email').toPromise();
        }
        if (botUpdateStatuses.text) {
          await this.vlaService.rebuildAndUpdateEmailTextVLAPrompt(this.user.textMessageAssistantId, 'text').toPromise();
        }
        if (botUpdateStatuses.voice) {
          await this.voiceVLAService.updateVoiceAssistantPrompt(this.voiceAssistant.id).toPromise();
        }
      } catch (err) {
        // no-op
      }
      this.toastService.showError('There was an error updating your VLA settings.');
    }
    this.savingSettings = false;
  }
}

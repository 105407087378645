import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { BrandingsService, WebsocketsService } from '@app/_services';
import { AuthenticationService } from '@app/_services/authentication.service';
import { FullStoryService } from '@app/_services/fullstory.service';

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    error = '';

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private authenticationService: AuthenticationService,
        private websocketsService: WebsocketsService,
        private brandingsService: BrandingsService,
        private fullStoryService: FullStoryService,
    ) {

        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;
        this.authenticationService.login(this.f.email.value, this.f.password.value)
            .pipe(first())
            .subscribe(
                (data) => {
                    // no need to make the user wait for this
                    this.brandingsService.getBrandings(true).toPromise();
                    this.fullStoryService.setFullStoryUser(data?.user);
                    if (data?.user?.claims?.includes('group')) {
                        this.router.navigate(['/group']);
                    } else if (data?.user?.claims?.includes('partner')) {
                        this.router.navigate(['/admin']);
                    } else {
                        this.router.navigate(['/dashboard']);
                    }
                },
                error => {
                    if (!error || error.length === 0) {
                        error = 'Error logging in, please try again.';
                    }
                    this.error = error;
                    this.loading = false;
                });
    }
}

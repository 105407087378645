<!-- Update Usage Modal -->
<div id="account-mimic-warning" [class.admin]="isAdmin || isPartner" *ngIf="showMimicWarning">
  <p class="white">Warning! You are logged in as {{showMimicWarning}}</p>
  <p class="mt-1">
    <a class="button button-white button-small" *ngIf="isAdmin" (click)="showChangeUsage()">Update Usage</a>
  </p>
</div>

<div id="app-container" [ngStyle]="{'height': adjustedHeight}">
  <nav class="navbar navbar-expand-lg navbar-light bg-light" id="sidebar-wrapper" *ngIf="(regexIndexOf(noSideBarStates, router.url) === -1)">
    <a class="navbar-brand" href="/">
      <img class="logo" [src]="brandingData.logoUrl" />
    </a>

    <!-- Standard navigation -->
    <div *ngIf="(!isGroup && !isPartner) || isMimicAccount">
      <ul class="navbar-nav">
        <li class="nav-item active">
          <a routerLink="/admin" [class.active]="router.url === '/admin'" *ngIf="isAdmin || isPartner">
            <div class="row nav-item">
              <div class="nav-icon">
                <i class="far fa-users-crown"></i>
              </div>
              <div class="nav-text">
                Admin
              </div>
            </div>
          </a>
        </li>
        <li class="nav-item active">
          <a routerLink="/group" [class.active]="router.url === '/group'" *ngIf="isGroup">
            <div class="row nav-item">
              <div class="nav-icon">
                <i class="fa-thin fa-people-roof"></i>
              </div>
              <div class="nav-text">
                Properties
              </div>
            </div>
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/dashboard" [class.active]="router.url.indexOf('/dashboard') > -1 && router.url.indexOf('vla') === -1">
            <div class="row nav-item">
              <div class="nav-icon">
                <i class="far fa-chart-pie"></i>
              </div>
              <div class="nav-text">
                Reports
              </div>
            </div>
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/blasts" [class.active]="router.url.indexOf('/blasts') > -1" *ngIf="!isRealEstate">
            <div class="row nav-item">
              <div class="nav-icon">
                <i class="far fa-bullhorn"></i>
              </div>
              <div class="nav-text">
                Blasts
              </div>
            </div>
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/vla" [class.active]="router.url.indexOf('/vla') > -1 && router.url.indexOf('settings') === -1">
            <div class="row nav-item">
              <div class="nav-icon">
                <i class="fa-regular fa-brain-circuit"></i>
              </div>
              <div class="nav-text">
                VLA
              </div>
            </div>
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/automation" [class.active]="router.url.indexOf('/automation') > -1 && router.url.indexOf('vla') === -1">
            <div class="row nav-item">
              <div class="nav-icon">
                <i class="fa-regular fa-wand-sparkles"></i>
              </div>
              <div class="nav-text">
                Automations
              </div>
            </div>
          </a>
        </li>
        <li class="nav-item">
          <a routerLink="/forms" [class.active]="router.url.indexOf('/forms') > -1">
            <div class="row nav-item">
              <div class="nav-icon">
                <i class="fa-regular fa-file-lines"></i>
              </div>
              <div class="nav-text">
                Surveys
              </div>
            </div>
          </a>
        </li>
        <li class="nav-item nb-dropdown">
          <a (click)="toggleDropdown('other')">
            <div class="row nav-item">
              <div class="nav-text">
                More
              </div>
              <div class="nav-icon right">
                <i class="fa-regular fa-chevron-down"></i>
              </div>
            </div>
          </a>
          <div class="nb-dropdown-wrapper" *ngIf="dropdownMenus.other.active">
            <li (click)="toggleDropdown('other')" class="nav-item">
              <a routerLink="/contacts" [class.active]="router.url.indexOf('/contact') > -1">
                <div class="row nav-item">
                  <div class="nav-icon">
                    <i class="far fa-id-card"></i>
                  </div>
                  <div class="nav-text">
                    Contacts
                  </div>
                </div>
              </a>
            </li>
            <li (click)="toggleDropdown('other')" class="nav-item" *ngIf="!claimsData.includes('vla')">
              <a routerLink="/conversations" [class.active]="router.url === '/conversations'">
                <div class="row nav-item">
                  <div class="nav-icon">
                    <i class="far fa-comments"></i>
                  </div>
                  <div class="nav-text">
                    Conversations <div class="unread-message-indicator" *ngIf="unreadMessages > 0">{{unreadMessages}}</div>
                  </div>
                </div>
              </a>
            </li>
            <li (click)="toggleDropdown('other')" class="nav-item">
              <a routerLink="/tracking" [class.active]="router.url === '/tracking'">
                <div class="row nav-item">
                  <div class="nav-icon">
                    <i class="far fa-list"></i>
                  </div>
                  <div class="nav-text">
                    Tracking
                  </div>
                </div>
              </a>
            </li>
          </div>
        </li>
        <li class="nav-item" placement="bottom" ngbTooltip="Logout">
          <a (click)="logout()">
            <div class="row nav-item">
              <div class="nav-icon">
                <i class="far fa-sign-out"></i>
              </div>
            </div>
          </a>
        </li>
        <li class="nav-item" placement="bottom" ngbTooltip="Settings" >
          <a routerLink="/settings" [class.active]="router.url.indexOf('/settings') > -1">
            <div class="row nav-item">
              <div class="nav-icon">
                <i class="far fa-cog"></i>
              </div>
            </div>
          </a>
        </li>
        <li class="nav-item" placement="bottom" ngbTooltip="FAQs" >
          <a href="https://nurtureboss.knowledgeowl.com/help" target="_blank">
            <div class="row nav-item">
              <div class="nav-icon">
                <i class="far fa-question"></i>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>

    <!-- Parnter navigation -->
    <div *ngIf="isPartner && !isMimicAccount">
      <ul class="navbar-nav">
        <li class="nav-item active">
          <a routerLink="/admin" [class.active]="router.url === '/admin'" *ngIf="isAdmin || isPartner">
            <div class="row nav-item">
              <div class="nav-icon">
                <i class="far fa-users-crown"></i>
              </div>
              <div class="nav-text">
                Admin
              </div>
            </div>
          </a>
        </li>
        <li class="nav-item" placement="bottom" ngbTooltip="Logout">
          <a (click)="logout()">
            <div class="row nav-item">
              <div class="nav-icon">
                <i class="far fa-sign-out"></i>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>

    <!-- Group navigation -->
    <div *ngIf="isGroup && !isMimicAccount">
      <ul class="navbar-nav">
        <li class="nav-item active">
          <a routerLink="/group" [class.active]="router.url === '/group'" *ngIf="isGroup">
            <div class="row nav-item">
              <div class="nav-icon">
                <i class="fa-thin fa-people-roof"></i>
              </div>
              <div class="nav-text">
                Properties
              </div>
            </div>
          </a>
        </li>
        <li class="nav-item" placement="bottom" ngbTooltip="Logout">
          <a (click)="logout()">
            <div class="row nav-item">
              <div class="nav-icon">
                <i class="far fa-sign-out"></i>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </nav>
  <main id="page-content-wrapper">
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>

<!-- TODO: Do we want this still? -->
<app-user-audits-widget *ngIf="(regexIndexOf(noSideBarStates, router.url) === -1)"></app-user-audits-widget>

<!-- Toast Template -->
<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>

<!-- Loading Template -->
<div id="global-loader" *ngIf="loaderActive">
  <div class="lds-grid">
    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
    <p class="long-load-message" *ngIf="longLoaderActive">Hang tight, this may take a few seconds.</p>
  </div>
</div>

<!-- Terms of Service Update -->
<div id="global-tos-update" *ngIf="showTosUpdate">
  <div class="tos-update-body">
    <img src="/assets/NB-logo-small.png" />
    <p>We've updated our Terms</p>
    <span>To continue please confirm that you agree to our Terms of Service</span>
    <a href="https://nurtureboss.io/terms-of-service/" target="_blank">Terms of Service</a>
    <button class="button button-dark" (click)="agreeToTerms()">I Agree</button>
  </div>
</div>

<div id="update-usage-modal" *ngIf="showUpdateUsageModal">
  <div class="usage-update-body">
    <p>Enter the new amount of usage for this account:</p>
    <div class="form-group">
      <label># of pages and texts</label>
      <input type="number" class="form-control" [(ngModel)]="newUsageAmount" [ngModelOptions]="{standalone: true}" id="newUsageAmount">
    </div>
    <button class="button button-dark" (click)="updateUsage()">Add Usage</button>
    <button class="button button-white margin-top-12" (click)="showChangeUsage()">Exit</button>
  </div>
</div>
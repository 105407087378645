import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { APIHelperService } from './apiHelper.service';

@Injectable({ providedIn: 'root' })
export class VLAService {

  constructor(
    private http: HttpClient,
    private apiHelper: APIHelperService
  ) {
    // no-op
  }

  enableVLA() {
    return this.http.post<any>(this.apiHelper.fillUrl('vla', {}, {}), {  })
      .pipe(map(data => {
        return data;
      }));
  }

  enableVoiceVLA() {
    return this.http.post<any>(this.apiHelper.fillUrl('voiceVla', {}, {}), {  })
    .pipe(map(data => {
      return data;
    }));
  }
  
  rebuildAndUpdateEmailTextVLAPrompt(id, type) {
    return this.http.put<any>(this.apiHelper.fillUrl('rebuildAndUpdateEmailTextVLAPrompt', { id, type }, {}), {})
    .pipe(map(data => {
      return data;
    }));
  }
}

// Angular:
import { Component, Input, OnInit, OnDestroy, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
// Libs:
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
// Services:
import {
  AuthenticationService,
  LoaderService,
  SpamLanguageGroupService,
  SpamLanguageService,
  TemplatesService,
  UsersService,
} from '@app/_services';
import { AutomationSettingsService } from '@app/_services/automationSettings.service';
import { PagePreviewService } from '@app/_services/pagePreview.service';
import { NurtureBossModalService } from '@app/_services/modal.service';
// Types:
import { AutomationStepExecutionMode, ConclusionAction, SystemAutomation } from '@nurtureboss/common/dist/types/automations';
import { ITemplate, TemplateMessagingTypes } from '@nurtureboss/common/dist/types/templates';
import { BuildYourOwnStep } from '../byoa.types';
// Utils:
import { deleteFromIndexedMapAndRearrangeOrder } from '@app/_utils/maps';

const NEW_TEMPLATE_DEFAULT_VERSION = 2;

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.less']
})
export class StepComponent implements OnInit, OnDestroy, OnChanges {

  @Input() automationSteps: BuildYourOwnStep[] = [];
  @Input() automationId?: string = null;
  @Input() steps: FormArray;
  @Input() templates: ITemplate[];
  @Input() branding: { fontFamily: string; primaryColor: string; };
  @Input() templateDefaults: any[] = [];
  @Input() isSystemAutomation = false;
  @Input() stepExecutionMode = AutomationStepExecutionMode.Chronologically;
  @Input() automationName?: string = null;
  @Input() isEntrataUser: boolean;
  @Input() canUseConclusionStep: boolean;
  @Input() isConclusionStepDisabled: boolean;
  @Input() isVLA: boolean = false;
  @Input() step1Delay: FormControl | null = null;
  @Input() isPostTourAutomation: boolean;

  @Output() onTemplateDefaultsChanged = new EventEmitter();

  activeStepDefaults?: any = null;
  activeStepIndex: number;
  activeTemplateDefaultId: string | null = null;
  activeTemplateId: string;
  activeTemplateName: string | null = null;
  activeTemplateSupportsEmail = false;
  activeTemplateSupportsText = false;
  activeSpamLanguageService: SpamLanguageService | null = null;
  changeSubscriptions: Map<number, Subscription>;
  spamSubscriptions: Subscription[] = [];
  forcedTemplateDisplay: string | null = null;
  messagesPerStep: Map<number, number>;
  pagePreviewUrl: SafeResourceUrl;
  previewUrl: SafeResourceUrl;
  previousStepDefaults: Map<number, Map<string, any>>;
  propertyName: string;
  selectedTemplate: ITemplate;
  stepDefaults: Map<number, any>;
  userId: string;
  validStepDefaults: Map<number, boolean>;
  templateTokens: Map<string, string[]>;
  modalSelectedStepIndex: number;
  loaded: boolean = false;
  emailSubjectLineSpamWarningsList = [];

  constructor(
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private authService: AuthenticationService,
    private automationService: AutomationSettingsService,
    private templateService: TemplatesService,
    private loaderService: LoaderService,
    private pagePreviewService: PagePreviewService,
    private nbModalService: NurtureBossModalService,
    private usersService: UsersService,
    private spamLanguageGroupService: SpamLanguageGroupService,
  ) {
    // no-op
  }

  ngOnInit(): void {
    this.userId = this.authService.currentUserValue.user._id;
    this.propertyName = this.authService.currentUserValue.user.propertyName;
    this.activeStepIndex = 0;
    this.previewUrl = null;
    this.pagePreviewUrl = null;
    this.stepDefaults = new Map<number, any>();
    this.validStepDefaults = new Map<number, boolean>();
    this.changeSubscriptions = new Map<number, Subscription>();
    this.messagesPerStep = new Map<number, number>();
    this.previousStepDefaults = new Map<number, Map<string, any>>();
    this.templateTokens = this.getTemplateTokens();
    this.usersService.loadGlobalDefaults();
  }

  ngOnDestroy(): void {
    // Cleanup all of the active subscriptions to avoid a memory leak
    for (const subscription of Array.from(this.changeSubscriptions.values())) {
      subscription.unsubscribe();
    }

    this.spamSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  initSpamLanguageGroup(stepGroup, stepIndex, defaults) {
    const spamLanguageGroup = this.spamLanguageGroupService.addGroup();

    // Set Warning Text
    spamLanguageGroup.spamLanguageService.setWarningText('WARNING: This Follow Up contains words that are known to trigger Spam Filters for various Email Service Providers. Problomatic words found:');
    spamLanguageGroup.emailSubjectLineSpamLanguageService.setWarningText('WARNING: Your Email Subject contains words and symbols known to trigger Spam Filters for various Email Service Providers. The following issues were found:');
    
    // Set Should Display
    spamLanguageGroup.spamLanguageService.setShouldDisplay(true);
    spamLanguageGroup.emailSubjectLineSpamLanguageService.setShouldDisplay(true);

    // Set Context for parsing tokens
    spamLanguageGroup.spamLanguageService.setContext(defaults);
    spamLanguageGroup.emailSubjectLineSpamLanguageService.setContext(defaults);

    // Check email subject line for spam when value changes
    this.spamSubscriptions.push(stepGroup.controls.emailSubjectLine.valueChanges.pipe(debounceTime(1000), distinctUntilChanged()).subscribe(value => {
      spamLanguageGroup.emailSubjectLineSpamLanguageService.checkForSpamLanguage(value);
    }));

    // Set new warnings to display them in UI
    this.spamSubscriptions.push(spamLanguageGroup.emailSubjectLineSpamLanguageService.warnings.subscribe(warnings => {
      // This array is used for email subject line warnings since steps are not in sub components
      // There may be a cleaner way to get warning directly from EmailSubjectLineSpamLanguageService
      this.emailSubjectLineSpamWarningsList[stepIndex] = warnings;
    }));

    // Check defaults for spam language
    // iPhone preview subscribes to any warnings found here and displays them there
    this.checkDefaultsForSpamLanguage(stepGroup.controls.templateId.value, this.stepDefaults.get(stepIndex), spamLanguageGroup.spamLanguageService);
  }

  checkDefaultsForSpamLanguage(templateId, stepDefaults, spamLanguageService) {
    const template = this.findTemplateById(templateId);

    if (template?.groups) {
      template.groups.forEach(group => {
        group.fields.forEach(field => {
          if (field.dataSource === 'template' && (field.type === 'text' || field.type === 'tokentext')) {
            spamLanguageService.checkForSpamLanguage(field.display, stepDefaults[field.name]);
          }
        })
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // populate the step forms
    if (changes.automationSteps && changes.automationSteps.currentValue.length > 0) {
      for (const step of changes.automationSteps.currentValue) {
        if (step.conclusionAction) {
          this.addConclusionStep(step);
        } else {
          this.addNewStep(step);
        }
      }
      this.onTemplateDefaultsChanged.emit(Array.from(this.stepDefaults.entries()));
      this.activeStepIndex = -1;
      this.activeTemplateDefaultId = null;
      this.activeTemplateName = null;
      this.activeTemplateId = null;
      this.activeStepDefaults = null;
    }
    if (changes.templates?.currentValue.length) {
      this.templateTokens = this.getTemplateTokens();
    }

    // Allow all spam language services to load and run before rendering UI
    this.loaded = true;
  }

  hasTriggers() {
    return this.stepExecutionMode === AutomationStepExecutionMode.Independently;
  }

  hasConclusionStep() {
    return this.steps.value.length && !!this.steps.value[this.steps.value.length -1].conclusionAction;
  }

  getSpamLanguageGroup(stepIndex) {
    return this.spamLanguageGroupService.getGroup(stepIndex);
  }

  getTemplateTokens() {
    const tokensMap = new Map();
    this.templates.forEach(template => {
      const templateTokens = [];
      template.groups.forEach(group => {
        group.fields?.forEach(field => {
          templateTokens.push(field.name);
        });
      });
      tokensMap.set(template._id, templateTokens);
    });

    return tokensMap;
  }

  findTemplateDefaultsForStep(templateName, stepId, stepIndex) {
    let acceptedDefault = null;

    // Check for a previously set step default during this session
    if (this.previousStepDefaults.has(stepIndex)) {
      if (this.previousStepDefaults.get(stepIndex).has(templateName)) {
        return {...this.previousStepDefaults.get(stepIndex).get(templateName)};
      }
    }

    if (stepId) {
      // Check for multiple defaults and grab the most recent.
      acceptedDefault = this.templateDefaults.find(({ automationStepId, templateName: defaultTemplateName, version }) =>
        automationStepId === stepId
        && version === NEW_TEMPLATE_DEFAULT_VERSION
        && defaultTemplateName === templateName
      );
    }
    if (acceptedDefault) {
      return acceptedDefault;
    }

    for (const templateDefault of this.templateDefaults) {
      if (
        (templateDefault.version && templateDefault.version === NEW_TEMPLATE_DEFAULT_VERSION)
        && templateDefault.templateName === templateName
        && !templateDefault.automationStepId
      ) {
        acceptedDefault = templateDefault;
        break;
      } else if (
        (!templateDefault.version || templateDefault.version < NEW_TEMPLATE_DEFAULT_VERSION)
        && templateDefault.templateName === templateName
        && !acceptedDefault
      ) {
        acceptedDefault = templateDefault;
      }
    }

    if (!acceptedDefault) {
      return { templateName };
    }

    return acceptedDefault;
  }

  addPreviousStepDefault(stepIndex, templateName, defaultValue) {
    if (!this.previousStepDefaults.has(stepIndex)) {
      this.previousStepDefaults.set(stepIndex, new Map<string, any>());
    }
    this.previousStepDefaults.get(stepIndex).set(templateName, {...defaultValue});
  }

  duplicateStep(e, stepData) {
    this.addNewStep(stepData);
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    e.stopPropagation();
  }

  addNewStep(initialValues?: BuildYourOwnStep) {
    this.pagePreviewUrl = null;
    this.previewUrl = null;
    const index = this.steps.length;
    const stepGroup = new FormGroup({
      templateId: new FormControl('', [
        Validators.required,
      ]),
      templateName: new FormControl(''),
      name: new FormControl('', [
        Validators.required,
      ]),
      emailSubjectLine: new FormControl('', [
        Validators.required,
        Validators.minLength(4),
      ]),
      textMessage: new FormControl('', [
        ...(this.automationName === SystemAutomation.TextOptInAutomation ? [] : [Validators.required, Validators.minLength(4)]),
      ]),
      hoursSinceLastEvent: new FormControl(0, [
        Validators.min(0),
      ]),
    });

    // Add default name in pre-build when add step manually
    if (this.isSystemAutomation && !Boolean(initialValues)) {
      stepGroup.controls.name.setValue(`Follow Up #${this.steps.value.length + 1}`, {
        onlySelf: true,
        emitEvent: false,
      });
    }

    const changeSubscription = stepGroup.controls.templateId.valueChanges.subscribe((templateId) => {
      // Use activeStepIndex since scoped index above might not accurate if steps have been added or deleted
      if (this.stepDefaults.has(this.activeStepIndex)) {
        const prevDefault = this.stepDefaults.get(this.activeStepIndex);
        this.addPreviousStepDefault(this.activeStepIndex, prevDefault.templateName, prevDefault);
      }

      if (!templateId) {
        this.previewUrl = null;
        this.pagePreviewUrl = null;
        this.activeTemplateDefaultId = null;
        this.activeTemplateName = null;
        this.activeTemplateId = null;
        this.activeStepDefaults = null;
        this.activeTemplateSupportsEmail = false;
        this.activeTemplateSupportsText = false;
        stepGroup.controls.templateName.setValue('', {
          onlySelf: true,
          emitEvent: false,
        });
        return;
      }

      const template = this.templates.find(({_id}) => _id === templateId)!;
      this.activeTemplateId = templateId;
      this.activeTemplateSupportsEmail = template.supportedMessaging.includes(TemplateMessagingTypes.Email);
      this.activeTemplateSupportsText = template.supportedMessaging.includes(TemplateMessagingTypes.NurturePage);

      stepGroup.controls.templateName.setValue(template.templateName, {
        onlySelf: true,
        emitEvent: false,
      });

      if (this.activeTemplateSupportsText && !stepGroup.controls.textMessage.value) {
        stepGroup.controls.textMessage.setValue(template.defaultTextMessageContent, {
          onlySelf: true,
          emitEvent: false,
        });
      } else if (!this.activeTemplateSupportsText) {
        stepGroup.controls.textMessage.setValue('Text messaging not supported', {
          onlySelf: true,
          emitEvent: false,
        });
      }

      if (this.activeTemplateSupportsEmail && !stepGroup.controls.emailSubjectLine.value) {
        stepGroup.controls.emailSubjectLine.setValue(template.defaultEmailSubjectLine, {
          onlySelf: true,
          emitEvent: false,
        });
      } else if (!this.activeTemplateSupportsEmail) {
        stepGroup.controls.emailSubjectLine.setValue('Email not supported', {
          onlySelf: true,
          emitEvent: false,
        });
      }
      const stepDefault = this.findTemplateDefaultsForStep(template.templateName, initialValues ? initialValues.stepId : '', this.activeStepIndex);
      stepDefault.stepIndex = this.activeStepIndex;
      stepDefault.templateId = template._id;
      stepDefault.templateName = template.templateName;
      this.stepDefaults.set(this.activeStepIndex, stepDefault);
      this.activeStepDefaults = stepDefault;
      this.activeTemplateDefaultId = stepDefault._id;
      this.activeTemplateName = stepDefault.templateName;
      this.onTemplateDefaultsChanged.emit((Array.from(this.stepDefaults.entries())));
      this.validateStepDefaults(this.activeStepIndex);
      this.regenPreviewUrls(this.activeStepIndex);

      if (this.getSpamLanguageGroup(this.activeStepIndex)) {
        const { spamLanguageService, emailSubjectLineSpamLanguageService } = this.getSpamLanguageGroup(this.activeStepIndex);

        // Set context with new step defaults
        spamLanguageService.setContext(stepDefault);
        emailSubjectLineSpamLanguageService.setContext(stepDefault);
        
        // Reset all warnings
        spamLanguageService.resetWarnings();
        emailSubjectLineSpamLanguageService.resetWarnings();

        // Check new values for spam language
        emailSubjectLineSpamLanguageService.checkForSpamLanguage(stepGroup.controls.emailSubjectLine.value);
        this.checkDefaultsForSpamLanguage(template._id, stepDefault, spamLanguageService);
      }
    });

    this.steps.push(stepGroup);

    this.changeSubscriptions.set(index, changeSubscription);
    this.stepDefaults.set(index, {});
    this.validStepDefaults.set(index, false);
    this.messagesPerStep.set(index, 0);
    this.activeStepIndex = index;
    this.activeTemplateDefaultId = null;
    this.activeTemplateName = null;
    this.activeStepDefaults = null;
    if (initialValues) {
      const stepFormValues: any = { ...initialValues };
      delete stepFormValues.stepId;
      delete stepFormValues._id;
      stepGroup.setValue(stepFormValues);
      this.automationService.getMessagesSentForStep(initialValues.stepId).subscribe((data) => {
        this.messagesPerStep.set(index, data.result.count);
      });
    }

    this.initSpamLanguageGroup(stepGroup, index, this.stepDefaults.get(index));
  }

  addConclusionStep(initialValues?: BuildYourOwnStep) {
    this.pagePreviewUrl = null;
    this.previewUrl = null;

    // Need these values to pass validation checks
    const stepGroup = new FormGroup({
      templateId: new FormControl('n/a'),
      templateName: new FormControl('n/a'),
      name: new FormControl('n/a'),
      emailSubjectLine: new FormControl('n/a'),
      textMessage: new FormControl('n/a'),
      hoursSinceLastEvent: new FormControl(0, [
        Validators.min(0),
      ]),
      conclusionAction: new FormControl(ConclusionAction.MarkLeadAsLost, [
        Validators.required,
      ]),
    });

    this.steps.push(stepGroup);

    this.activeStepIndex = this.steps.length - 1;

    if (initialValues) {
      const stepFormValues: any = { ...initialValues };
      delete stepFormValues.stepId;
      delete stepFormValues._id;
      stepGroup.setValue(stepFormValues);
    }
  }

  findTemplateById(templateId: string): ITemplate {
    return this.templates.find((t) => t._id === templateId)!;
  }

  validateStepDefaults(stepIndex) {
    const stepGroup = (this.steps.at(stepIndex) as FormGroup);
    const templateId = stepGroup.controls.templateId.value;
    if (!templateId) {
      this.validStepDefaults.set(stepIndex, false);
      return;
    }

    const template = this.findTemplateById(templateId);
    const stepDefaultData = this.stepDefaults.get(stepIndex);
    const isValid = this.templateService.isTemplateDefaultValid(template, stepDefaultData);
    this.validStepDefaults.set(stepIndex, isValid);
  }

  regenPreviewUrls(stepIndex: number) {
    const stepGroup = this.steps.controls[stepIndex] as FormGroup;
    const stepDefault = this.stepDefaults.get(stepIndex);
    this.activeStepIndex = stepIndex;
    this.activeTemplateName = stepDefault.templateName;
    this.activeTemplateDefaultId = stepDefault._id;
    this.activeStepDefaults = stepDefault;

    const template = this.findTemplateById(stepGroup.controls.templateId.value);
    if (!template) {
      return;
    }

    let templateDisplay = null;
    this.activeTemplateSupportsEmail = template.supportedMessaging.includes(TemplateMessagingTypes.Email);
    this.activeTemplateSupportsText = template.supportedMessaging.includes(TemplateMessagingTypes.NurturePage);

    if (!this.activeTemplateSupportsText && this.activeTemplateSupportsEmail) {
      templateDisplay = 'email';
    } else if (this.activeTemplateSupportsText && !this.activeTemplateSupportsEmail) {
      templateDisplay = 'nurturepage';
    }

    this.forcedTemplateDisplay = templateDisplay;
    const dataForPreview = {
      yourWebsite: 'https://example.website.com',
      onlineApplicationUrl: 'https://example.application.com',
      yourPhoneNumber: '5555555555',
      yourEmailAddress: 'example@example.com',
      type: template.templateName,
      apartmentName: this.propertyName,
      propertyName: this.propertyName,
      clientFirstName: 'Nelli',
      appointmentDate: '01/15/2024',
      appointmentTime: '1:30 pm',
      eventName: 'Taco Tuesday',
      eventDate: '01/15/2024',
      eventTime: '1:30 pm',
      eventLocation: 'Outside The Leasing Office',
      apartmentNumber: '1032',
      scheduleATourUrl: 'https://example.website.com',
      ledgerBalance: '$325.50',
      floorPlanOne: 'AL',
      floorPlanTwo: 'AU',
      ...this.usersService.currentGlobalDefaultSocialLinks,
      ...stepDefault,
      ...this.branding,
    };
    this.previewUrl =  this.activeTemplateSupportsEmail ? this.pagePreviewService.getEmailPreviewUrl(template, dataForPreview) : null;
    this.pagePreviewUrl = this.activeTemplateSupportsText ? this.pagePreviewService.getPagePreviewUrl(template, dataForPreview) : null;
  }

  onTabOpen(event: { index: number }) {
    this.pagePreviewUrl = null;
    this.previewUrl = null;
    const stepGroup = this.steps.controls[event.index] as FormGroup;
    const stepDefault = this.stepDefaults.get(event.index);
    this.activeStepIndex = event.index;

    if (!stepGroup.controls.templateId.value) {
      return;
    }

    const template = this.findTemplateById(stepGroup.controls.templateId.value);
    this.activeTemplateName = template.templateName;
    this.activeTemplateId = template._id.toString();
    this.activeTemplateDefaultId = stepDefault._id;
    this.activeStepDefaults = stepDefault;
    if (!this.templateService.isTemplateDefaultValid(template, stepDefault)) {
      return;
    }

    this.regenPreviewUrls(event.index);
  }

  openDefaults(defaults) {
    this.selectedTemplate = this.templates.find((template: ITemplate) => {
      return template._id === this.activeTemplateId;
    });
    this.modalService.open(defaults, { windowClass : 'custom-modal-styles-new email-modal'}).result.then((result) => {
      // no-op
    }, () => {
      // no-op
    });
  }

  getTokensToHide(): string[] {
    return [
      'mainImage',
      'bannerImage',
      'centerImage',
      'centerLogo',
      'leftLogo',
      'onlineApplicationUrl',
      'onlineApplicationLinkText',
      'fileAttachment',
      'footerContent',
      'type',
      'created',
      'updated',
      '__v',
      '_id',
      'ownerId',
      'templateName',
      'pageName',
      'label',
      'yardiGuestCardId',
      'realPageGuestCardId',
      'knockProspectId',
      'entrataGuestCardId',
      'entrataApplicationId',
      'entrataApplicantId',
      'yardiTenantId',
      'realPageTenantId',
      'entrataTenantId',
      'resmanPersonId',
      'resmanLeaseId',
      'mainText',
      'headerText',
    ];
  }

  exitModal(e, modal) {
    e.preventDefault();
    modal.dismiss();
  }

  handleFormSubmitEvent(formData: {[key: string]: string}): void {
    const stepDefaults = this.stepDefaults.get(this.activeStepIndex);
    delete formData.propertyName;
    const def = {
      ...stepDefaults,
      ...formData,
      version: NEW_TEMPLATE_DEFAULT_VERSION,
      stepIndex: this.activeStepIndex,
      templateId: this.steps.controls[this.activeStepIndex].value.templateId,
    };
    this.stepDefaults.set(this.activeStepIndex, def);
    this.activeStepDefaults = def;

    const { spamLanguageService, emailSubjectLineSpamLanguageService } = this.getSpamLanguageGroup(this.activeStepIndex);

    // Update context with new values
    spamLanguageService.addContext(def);
    emailSubjectLineSpamLanguageService.addContext(def);
 
    // Check for spam language with new context
    emailSubjectLineSpamLanguageService.checkForSpamLanguage(this.steps.controls[this.activeStepIndex].value.emailSubjectLine.value);
    this.checkDefaultsForSpamLanguage(def.templateId, def, spamLanguageService);

    const finalizeStepUpdate = () => {
      this.onTemplateDefaultsChanged.emit((Array.from(this.stepDefaults.entries())));
      this.validateStepDefaults(this.activeStepIndex);
      this.regenPreviewUrls(this.activeStepIndex);
      this.modalService.dismissAll();
    };

    // Does this code ever run? I couldn't get it to run. 
    // Seems like it is trying to save the default if it is a new step
    if (
      this.automationId
      && this.automationSteps.length
      && this.automationSteps.length < this.activeStepIndex // Don't think this would ever be true
      && this.automationSteps[this.activeStepIndex]
      && this.automationSteps[this.activeStepIndex].stepId
    ) {
      this.loaderService.triggerLoader();
      this.templateService.storeUserAutomationDefault(
        this.automationId,
        this.automationSteps[this.activeStepIndex].stepId,
        this.activeTemplateName,
        formData,
      ).subscribe(() => {
        this.loaderService.stopLoader();
        finalizeStepUpdate();
      });
    } else {
      finalizeStepUpdate();
    }
  }

  getTokensArray(controlValue: string, tokensToAppend: Array<string>): Array<any> {
    return this.templateTokens.get(controlValue).concat(tokensToAppend) || [];
  }

  // Modals:
  confirmDeleteStep(step) {
    this.modalSelectedStepIndex = step;
    this.nbModalService.open('delete-step-modal');
  }

  closeModal(name: string) {
    this.modalSelectedStepIndex = null;
    this.nbModalService.close(name);
  }

  deleteStep(index: number) {
    if (this.activeStepIndex === index) {
      this.activeStepIndex = 0;
      this.regenPreviewUrls(0);
    }

    this.spamLanguageGroupService.removeGroup(index);
    this.emailSubjectLineSpamWarningsList.splice(index, 1);

    if(!this.steps.value[index].conclusionAction) {
      deleteFromIndexedMapAndRearrangeOrder(index, this.stepDefaults);
      deleteFromIndexedMapAndRearrangeOrder(index, this.validStepDefaults);
      this.onTemplateDefaultsChanged.emit(Array.from(this.stepDefaults.entries()));
      this.changeSubscriptions.get(index).unsubscribe();
      deleteFromIndexedMapAndRearrangeOrder(index, this.changeSubscriptions);
      deleteFromIndexedMapAndRearrangeOrder(index, this.messagesPerStep);
    }
    this.steps.removeAt(index);
    this.nbModalService.close('delete-step-modal');
  }

  formatConclusionActionDisplay(conclusionAction: ConclusionAction): string {
    switch(conclusionAction) {
      case ConclusionAction.MarkLeadAsLost:
        return 'Mark Lead As Lost';
      default:
        throw new Error(`Invalid conclusion action ${conclusionAction}`);
    }
  }

  preventDecimals(event: any) {
    event.target.value = event.target.value.replace(/[^0-9-]/g, '');
  }
}
